// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';

#productLineGalleryModal {
	padding-right: 0 !important;

	.modal-content {
		box-shadow: 1px 5px 10px 10px rgba(0, 0, 0, 0.5);
	}
}

.text-tan {
	color: $tan !important;
}

.navbar-laravel {
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.04);
}
html {
	scroll-behavior: smooth;
	font-size: 93.75%;
	line-height: 1.5em;
}

body {
	color: $gray;
	font-size: 1.066666667rem;
	line-height: 1.4em;
	font-family: $arial;
	background-color: #fff;
	overflow-x: hidden;
	overscroll-behavior: none;
}

h1, h2, h3 {
    letter-spacing: -1px;
}

h1, h3, legend, .emphasis {
	color: $tan;
}

.emphasis, b, strong {
	font-weight: bold;
}

.text-note {
    font-size: 0.9em;
    font-style: italic;
}

.separator {
	padding: 0 10px;
	font-size: 1rem;
	line-height: 1em;
}

@media only screen and (max-width: 767px) {
	.pl-4 {
		padding-left: 15px !important;
	}

	.pr-4 {
		padding-right: 15px !important;
	}
}

@media (min-width: 576px) {
	.container {
    	max-width: 100%;
	}
}

@media (min-width: 768px) {
	.container {
    	max-width: 100%;
	}
}

@media (min-width: 992px) {
	.container {
	   max-width: 100%;
	}
}

@media (min-width: 1200px) {
	.container {
	    max-width: 1140px;
	}
}

h1 {
	font-family: $pragmatica;
	font-size: 2.4rem;
	line-height: 1.1em;
	margin: 0 0 0.25em;

	&.model-title {
		margin-bottom: 0.5em;
		font-size: 2.8rem;
		line-height: 0.8em;
		color: $gray-light;
		padding-top: 5px;

		.header-emphasis {
			font-size: 0.762em;
			text-transform: uppercase;
			color: $gray;
			display: inline-flex;
			flex-flow: wrap;
			max-width: 50%;
		}

		small {
			font-size: 70%;
			display: inline-block;
			vertical-align: middle;
		}

		@media only screen and (max-width: 991px) {
      .header-emphasis {
        max-width: 80%;
      }

			.float-right {
				float: none !important;
				margin: 15px 0 0;
				display: block;
			}
		}

		@media only screen and (max-width: 650px) {
			font-size: 2rem;
			line-height: 1em;
		}
	}
}

h2 {
	font-family: $pragmatica;
	font-size: 2rem;
	line-height: 1.1em;
	margin: 0 0 0.5em;

	&.column-header {
		font-size: 1.8rem;
		line-height: 1em;
		margin-bottom: 0.66em;
		text-align: center;
		text-transform: uppercase;
	}
}

h3 {
	margin: 0;

	* + & {
		margin-top: 1.5em;
	}

	&.text-separator {
		font-family: $pragmatica;
		font-size: 2rem;
		line-height: 1em;
		text-transform: uppercase;
		margin: 3rem auto;
		position: relative;
		text-align: center;
		color: $tan-light;

		&:before, &:after {
			position: absolute;
			top: 50%;
			width: 7.5%;
			height: 1px;
			background-color: $tan-light;
			font-size: 1px;
			line-height: 1px;
			display: block;
			content: '';
			transform: translate(0, -50%);
		}

		&:before {
			left: 35%;
		}

		&:after {
			right: 35%;
		}
	}
}

h4 {
	* + & {
		margin-top: 1.5em;
	}
}

p {
	margin: 0;
	font-size: (16/15) * 1rem;

	* + & {
		margin-top: 1em;
	}
}

ul, ol {
	* + & {
		margin-top: 0.5em;
	}
}

.col-description {
	@media only screen and (max-width: 767px) {
		margin-bottom: 1.5rem !important;
	}
}

// Admin specific styles

address {
	margin-bottom: 0;
}

.list-flex {
	width: 100%;
	padding: 0;
	display: flex;
	flex-flow: wrap;

	li {
		display: inline-block;
		flex-basis: 25%;
		margin-bottom: 5px;
	}
}

.clear {
	clear: both;
	font-size: 0;
	line-height: 0;
	height: 0;
	width: 100%;
	display: block;
}

.card-header {
	padding: 1.25rem;

	h1 {
		&.d-inline-block {
			margin-bottom: 0;
		}
	}
}

.btn-toolbar {
	a:hover {
		text-decoration: none;
	}

	.input-group {
		flex-basis: 33%;

		.input-group-text {
			line-height: 1em;
			cursor: pointer;
		}
	}

	.btn-group {
		.badge {
			margin-left: 5px;
		}

		.dropdown-menu {
			.dropdown-item {
				display: flex;
				flex-flow: wrap;
				justify-content: space-between;
				line-height: 1em;
				align-items: baseline;
				padding: 0.5rem 1rem;

				&:nth-child(odd) {
					@extend .bg-light;
				}

				&:hover {
					background-color: #dae0e5 !important;
				}
			}
		}
	}
}

.custom-select[multiple], .custom-select[size]:not([size="1"]) {
    padding: 0;
    font-size: 0.95rem;
    line-height: 1.2em;
    height: 250px;

    option {
        padding: 0.25rem 0.5rem;
        border-bottom: 1px solid rgba($gray, 0.2);
    }
}

.filter-toggle {
	.lead {
		font-size: 1.2rem;
	}

	.btn-group-inline {
		margin-left: 5px;
		vertical-align: baseline;

		.btn {
			padding: 0.4rem 0.75rem;
			line-height: 1em;

			&:nth-child(odd) {
				border-right: 1px solid #fff;
			}

			&:nth-child(even) {
				border-left: 1px solid #fff;
			}
		}
	}
}

.form-registration, .form-create {
	.form-group {
		label {
			@extend %screen-reader-text;

			&.label-show {
				left: auto;
				top: auto;
				position: static;
				@extend h5;

				&.custom-file-label {
					position: relative;
					width: 100%;
					font-size: 0.9rem;
					line-height: 1.6;
					color: rgba($gray, 0.75);

					&.is-invalid {
						color: $red;
						border-color: $red;

						&:after {
							background-color: $red;
							color: #fff;
						}

						& ~ input ~ .invalid-feedback {
							display: block;
						}
					}
				}

				&.form-check-label {
					font-size: 0.9rem;
					line-height: 1.6;
					margin-bottom: 0;
				}
			}

			&.label-spec {
				font-size: 0.9rem;
				font-weight: bold;
			}
		}
	}
}

.form-group {
	.form-check-inline {
		label {
			&.form-check-label {
				margin-bottom: 0;
			}
		}
	}
}

.form-check {
	&:not(.form-check-inline) {
		margin-bottom: 0.5rem;
	}

	.form-check-label {
		font-size: 0.9rem;
		line-height: 1.6;
	}

	&.is-invalid {
		.form-check-label {
			color: $red;
		}
	}
}

.form-text {
	font-size: 0.9em;
}

.custom-file {
	margin-top: 1.25rem;

	.container-edit & {
		margin-top: 0;
		height: auto;
	}
}

.custom-file-input {
	display: none;
}

.form-check-input {
	margin-top: 0.25rem;
}

.col-columns-2 {
	columns: 2;

	h5 {
		display: block;
		width: 100%;
	}
}

fieldset {
	legend {
		margin-bottom: 35px;
	}

	& + fieldset {
		margin-top: 10px;

		legend {
			padding-top: 35px;
			border-top: 8px solid rgba($gray, 0.25);
		}
	}
}

.jumbotron {
    padding: 2rem;
}

.card-admin_list {
	.list-group-item {
		span {
			flex-basis: 23.333333333333333%;
			display: block;
			padding: 0 15px 0 0;
			font-size: 1rem;

			p {
				font-size: 1em;
			}

			img {
				width: 100%;
				height: auto;
				display: block;
				margin: 0 auto;
			}

			&:last-child {
				padding: 0;
			}

			&.column-center {
				flex-basis: 10%;
				text-align: center;
				padding: 0;
			}

			&.column-long {
				flex-basis: 27.333333333333333%;
			}

			&.column-one-third {
				flex-basis: 33.333333333333333%;
			}

			&.column-short {
				flex-basis: 18%;
			}

			&.column-half {
				flex-basis: 50%;
			}

			&.column-desc {
				flex-basis: 56%;
			}

			&.column-three-quarter {
				flex-basis: 75%;
			}

			&.column-one-quarter {
				flex-basis: 25%;
			}

			&.column-image {
				flex-basis: 16.666666666666667%;
				text-align: center;
				padding: 0;
			}

			.svg-inline--fa {
				font-size: 1.4em;
				display: block;
				margin: 0 auto;
			}

			&.admin-actions {
				flex-basis: 46.6666666666%;
				padding-top: 10px;
				opacity: 0.5;
				transition: opacity 0.33s ease;

				&:hover {
					opacity: 1;
				}

				a + a {
					margin-left: 5px;
				}

				.btn-sm {
					line-height: 1.25em;
				}
			}

			&.admin-notice {
				flex-basis: 53.3333333334%;
				padding-top: 10px;
				font-style: italic;
			}
		}

		&:nth-child(odd) {
			background-color: rgba($gray-light, 0.15);
		}

		&.list-group-header {
			background-color: #c6c8ca;

			span {
				padding: 0;
				font-weight: bold;
			}
		}
	}
}

.btn {
	.svg-inline--fa {
		margin-left: 5px;
	}

	.badge {
		&.badge-lg {
			font-size: 100%;
			vertical-align: middle;
			padding: 0 0 0 0.5em;
		}
	}

    &.btn-select-multi, &.btn-select-order {
        .svg-inline--fa {
            margin: 0 auto;
            font-size: 1.2rem;
        }
    }

	&.btn-resource {
		background-color: lighten(#c4c4c4, 10%);
		color: $gray !important;
        margin-bottom: 0.33em;

		.svg-inline--fa {
			margin: -1px 5px 0 0;
			font-size: 1.25em;
			line-height: 1em;
			vertical-align: middle;
			color: darken($gray, 10%);
		}

		&:hover {
			background-color: #c4c4c4;
		}

		@media only screen and (max-width: 650px) {
			padding: 0.3rem 0.7rem !important;
			font-size: 1rem !important;
		}
	}

	&.btn-js {
		& + .btn-js {
			@extend .ml-1;
		}
	}

	&.btn-lightbox {
		background-color: $townsend-blue;
		color: #fff !important;

		&:hover {
			background-color: darken($townsend-blue, 15%);
		}
	}

	&.btn-order {
		background-color: $townsend-orange;
		color: #fff !important;

		&:hover {
			background-color: darken($townsend-orange, 15%);
		}

        &.large {
            padding: 5px 30px;
            font-size: 21px;
        }
	}

	&.btn-pdf {
		background-color: $townsend-green;
		color: #fff !important;

		&:hover {
			background-color: darken($townsend-green, 15%);
		}
	}

	&.btn-tan {
		background-color: $tan-light;
		color: #fff !important;

		&:hover {
			background-color: $tan;
		}
	}
}

.btn-md, .btn-group-md > .btn {
    padding: 0.425rem 0.85rem;
    font-size: 1.066666667rem;
    line-height: 1.5;
    border-radius: 0.3rem;
}

.btn-group-2 {
	font-family: $roboto-condensed;
	font-weight: bold;

	.btn:first-child {
		border-right: 1px solid #fff;
	}

	.btn:last-child {
		border-left: 1px solid #fff;
	}

	&.btn-group-lg {
		.btn-lg {
			font-size: 1.4rem;
		}
	}
}

.btn-group-even {
	font-family: $roboto-condensed;
	font-weight: bold;
	display: flex;
	flex-flow: row;
	justify-content: center;

	.btn {
		flex: 1 1 0;
	}

	.btn:first-child {
		border-right: 1px solid #fff;
	}

	.btn:last-child {
		border-left: 1px solid #fff;
	}

	&.btn-group-lg {
		.btn-lg {
			font-size: 1.4rem;
		}
	}
}

.ck-editor__editable {
	height: 250px;

	.ckeditor-sm & {
		height: 150px;
	}
}

.btn-add-spec {
	margin-left: 5px;
}

.additional-spec_container {
	width: 100%;
	display: flex;
	flex-flow: wrap;

	.form-group {
		padding-right: 5px;
		padding-left: 5px;
	}

	.additional-spec {
		width: 100%;
		display: flex;
		flex-flow: wrap;

		.form-group {
			padding-right: 5px;
			padding-left: 5px;
		}
	}
}

.item-admin-function_wrap {
	.card-header {
		padding: 0;

		h2 {
			margin: 0;
			line-height: 1em;
		}

		.btn {
			@extend h3;
			padding: 0.75rem 1rem;
			margin: 0;
			display: block;
			width: 100%;
			text-align: left;

			&:hover {
				text-decoration: none;
			}
		}

		.btn-link:focus, .btn-link.focus {
			text-decoration: none;
		}
	}

	.card-body {
		padding-top: 1.5rem;

		.form-text {
			font-size: 0.85em;
			padding-top: 5px;
		}
	}
}

.created-inline-items {
	padding-bottom: 20px;

	h2 {
		color: $tan;
		margin-bottom: 1em;
	}

	.list-inline {
		flex-flow: wrap;

		.list-inline-item {
			flex-basis: 24%;
			font-size: 0.95em;
			margin: 0 0 15px !important;
			padding: 0 0 10px;
			border-bottom: 1px solid #dee2e6;
			background-color: #fff;

			&:nth-of-type(4n+2) {
				padding: 0 0.5% 0 1%;
				flex-basis: 25.5%;
			}

			&:nth-of-type(4n+3) {
				padding: 0 1% 0 0.5%;
				flex-basis: 25.5%;
			}

			&.edit-active {
				background-color: $blue;
				border: 1px solid $blue;
				color: #fff;

				.item-name {
					padding-left: 0.75rem;
					display: block;
				}
			}

			img {
				margin-bottom: 5px;
				position: relative;
				z-index: 5;
			}

			.item-thumb {
				display: block;
				position: relative;
        padding-top: 60%;
        overflow: hidden;

        img {
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          width: 100%;
          height: auto;
        }

				.item-details, .item-admin-actions {
					position: absolute;
					left: 0;
					top: 0;
					z-index: 10;
					opacity: 0.5;
					transition: opacity 0.25s ease;
				}

				&:hover {
					.item-details, .item-admin-actions {
						opacity: 1;
					}
				}

				.item-details {
					.item-detail {
						display: block;
						float: left;
						clear: both;
						background-color: $blue;
						color: #fff;
						font-size: 0.85em;
						line-height: 1em;
						padding: 0.5em 0.6em;

						&:empty {
							display: none;
						}

						& + .item-detail {
							margin-top: 1px;
						}
					}
				}

				.item-admin-actions {
					right: 0;
					left: auto;

					a, a:visited, a:active, a:focus {
						display: block;
						float: right;
						clear: both;
						background-color: $green;
						color: #fff;
						font-size: 0.85em;
						line-height: 1em;
						padding: 0.5em 0.6em;
						text-decoration: none;
					}

					a ~ a {
						margin-top: 1px;
						background-color: $red !important;

						&:hover {
							background-color: darken($red, 10%) !important;
						}
					}

					a:hover {
						text-decoration: none;
						color: #fff;
						background-color: darken($green, 10%);
					}
				}
			}

      .item-name {
        padding-top: 10px;
        display: block;
      }
		}
	}
}

.img-fluid-height {
	height: 100% !important;
}

// Front end styles
.tooltip {
	z-index: 999999;
	font-size: 0.85rem;
}

.site-header {
	background-color: #fff;
	position: relative;
	z-index: 20;
	padding-top: 25px;
	padding-bottom: 25px;


	@media only screen and (max-width: 767px) {
		padding-top: 0;
	}

	.content-pdf & {
		text-align: center;
		padding: 1rem 0 1.5rem;
		border-bottom: 1px solid $gray-light;
	}

	a {
		display: inline-block;
		vertical-align: middle;
		position: relative;
		z-index: 50;

		.content-pdf & {
			margin-left: 0;
		}

		&.logo-home_link {
            width: 22%;
			//width: (315 / 1100) * 100%;
			//height: (82 / 125) * 100%;

			@media only screen and (max-width: 767px) {
				width: 207px;
				height: 54px;
			}

			@media only screen and (max-width: 365px) {
				display: block;
				width: 100%;
				height: auto;
			}
		}
	}

	.nav-secondary {
		position: absolute;
		right: 15px;
		top: 0;
		margin: 0;

		@media only screen and (max-width: 767px) {
			position: relative;
			display: flex;
			flex-flow: wrap;
			width: calc(100% + 30px);
			top: 0;
			margin-bottom: 20px;
		}

		.nav-item {
			margin: 0;
			border-left: 1px solid #fff;

			@media only screen and (max-width: 767px) {
				flex-basis: 33.333333333333333%;
			}

			.nav-link {
				margin: 0;
				color: #fff;
				background-color: $townsend-blue;
				border-radius: 0;
				font-size: (14 / 15) * 1rem;
				padding: 0.33rem 0.67rem 0.4rem;
				display: block;
                text-align: center;
                text-transform: uppercase;

        &.nav-link_standout {
          background-color: $townsend-orange;
        }

        &.nav-link_subtle {
          background-color: $townsend-gray;
        }

				.svg-inline--fa {
					margin-left: 3px;
					font-size: 1em;
				}

				&.active, &:hover {
					background-color: darken($townsend-blue, 15%);

          &.nav-link_standout {
            background-color: darken($townsend-orange, 15%);
          }

          &.nav-link_subtle {
            background-color: darken($townsend-gray, 15%);
          }
				}

				@media only screen and (max-width: 991px) {
					font-size: (13 / 15) * 1rem;
					padding: 0.25rem 0.55rem 0.3rem;
				}

				@media only screen and (max-width: 767px) {
					border-radius: 0 !important;
				}
			}

			&:first-child {
				border-left: none;

				.nav-link {
					border-bottom-left-radius: 0.5em;
				}
			}

			&:last-of-type {
				.nav-link {
					border-bottom-right-radius: 0.5em;
				}
			}
		}
	}

	.logo-main {
		width: 100%;
		height: 100%;
		display: block;

		@media only screen and (max-width: 365px) {
			height: auto;
		}
	}

	/*.icon-container {
		display: inline-block;
		vertical-align: middle;
		margin-left: 60px;
		position: relative;
		height: 100%;
		width: (62 / 1110) * 100%;

		@media only screen and (max-width: 767px) {
			display: none;
		}

		&:before, &:after {
			width: 150%;
			height: 100%;
			position: absolute;
			top: 0;
			content: '';
			display: block;
		}

		&:before {
			background-image: linear-gradient(to left, #7e7e7e, #bbb 0%, #fff 90%);
			left: -100%;
		}

		&:after {
			background-image: linear-gradient(to right, #7e7e7e, #bbb 0%, #fff 90%);
			right: -100%;
		}

		.icon-header_t {
			height: 100%;
			width: 100%;
			display: block;
			position: relative;
			z-index: 25;
		}
	}*/

	.tagline-header {
		position: relative;
		z-index: 50;
		display: inline-block;
		vertical-align: middle;
		//margin-left: 20px;
        width: 30%;
		//width: (450 / 1110) * 100%;
		//height: (84 / 125) * 100%;

		@media only screen and (max-width: 767px) {
			width: 293px;
			height: 54px;
		}

		@media only screen and (max-width: 575px) {
			margin-left: 20px;
			margin-top: 15px;
			display: block;
		}

		@media only screen and (max-width: 365px) {
			display: none;
		}

		path {
			fill: $townsend-gray;
		}

		.content-pdf & {
			margin-left: 20px;
		}
	}
}

.site-nav-container {
	position: relative;
	z-index: 20;

  & > .row {
    background-color: #c4c4c4;
    position: relative;
    z-index: 10;
  }

  .site-nav-banner {
    padding: 0;
    text-align: center;
    transition: transform 0.3s ease;
    margin: 0 -15px;
    transform: translateY(0);
    position: relative;
    z-index: 5;

    &.site-nav-banner-hidden {
      transform: translateY(-100%);

        @media only screen and (max-width: 991px) {
            transform: translateY(0);
        }
    }

    p {
      text-align: center;
      font-weight: bold;
      padding: 10px 20px;
      background-color: #f9eeee;
      display:  block;
      margin: 0 auto;

      a {
          color: #102557;
      }

      @media only screen and (max-width: 991px) {
        display: block;
      }
    }
  }

	@media only screen and (max-width: 767px) {
		background: #9f9f9f;
	}

	.navbar-toggler {
		padding: 0.5rem 0.75rem;
		font-size: 1.2rem;
		line-height: 1em;
		background-color: transparent;
		border: none;
		border-radius: 0;
		color: #fff;

		.navbar-toggler-icon {
		    display: inline-block;
		    width: auto;
		    height: auto;
		    vertical-align: middle;
			font-size: 1.6rem;
			line-height: 1em;
			margin-right: 10px;
		}

		.navbar-toggler-text {
			display: inline-block;
			vertical-align: middle;
			font-size: 1.6rem;
			line-height: 1em;
		}
	}

	.container {
		background-image: linear-gradient(to right, #c4c4c4, #9f9f9f 5%, #9f9f9f 50%, #9f9f9f 95%, #c4c4c4);

		@media only screen and (max-width: 991px) {
			padding-left: 0;
			padding-right: 0;
		}

		@media only screen and (max-width: 767px) {
			background: #9f9f9f;
		}
	}

	.site-nav {
		padding: 0;
		justify-content: space-between !important;
		font-family: $roboto-condensed;
		font-weight: 300;
		font-size: (20/15) * 1rem;

    @media only screen and (max-width: 1150px) {
      font-size: 1.2rem;
    }

    @media only screen and (max-width: 1050px) {
      font-size: (16/15) * 1rem;
    }

    @media only screen and (max-width: 991px) {
      font-size: (20/15) * 1rem;
    }


    .search {
        padding: 10px 16px 10px 20px;
        background-color: #808084;
        svg {
            width: 22px;
            path {
                fill: #ffffff;
            }
        }
    }



		.navbar-nav {
			margin-top: 0;
		}

		.nav-item {
			@extend .border-left;
      @extend .border-white;

      &.nav-item-search {
        a {
          background-color: $townsend-orange;
        }

        a:hover {
          background-color: darken($townsend-orange, 15%);
        }
      }

			&:last-of-type {
				@extend .border-right;
				@extend .border-white;

				@media only screen and (max-width: 991px) {
					border: none !important;
				}
			}

			@media only screen and (max-width: 991px) {
				border: none !important;

				&.nav-item-search {
					display: none;
				}
			}
		}

		.nav-link {
			padding: 0.75rem 1.25rem;
			color: #fff;
            font-size: 15px;
            text-transform: uppercase;
            font-weight: 400;
            text-shadow: -1px -1px #737272;

			&:hover, &.active {
				background-color: #7c7c7c;
			}
		}
	}
}

.content-main {
	position: relative;
	z-index: 15;
	padding: 35px 15px;
	max-width: 1140px;
	background: linear-gradient(to bottom, #fcfcfc, #fff 64%);

	@media only screen and (max-width: 991px) {
		padding: 20px;
	}

	@media only screen and (max-width: 767px) {
		.col-md-4 + .col-md-8 {
			margin-top: 2rem;
		}
	}

  &.content-main-search {
    z-index: 35;
  }

	.content-pdf & {
		max-width: none;
		background: none;
	}

	/*&:before, &:after {
		position: absolute;
		width: 425px;
		height: 100%;
		top: 0;
		content: '';
		display: block;

		.content-pdf & {
			display: none;
		}
	}

	&:before {
		left: -425px;
		background: transparent url(../imgs/background-main_left.jpg) no-repeat 100% 0;
	}

	&:after {
		right: -425px;
		background: transparent url(../imgs/background-main_right.jpg) no-repeat 0 0;
	}*/

	a, a:visited, a:active, a:focus {
		color: $tan;
		text-decoration: none;

		&.btn {
			color: #fff;
		}

		&.carousel-control-next, &.carousel-control-prev {
			font-size: 3rem;
			color: $tan;
			opacity: 0.5;
			transition: opacity 0.25s ease;
		}
	}

	a:hover {
		color: darken($tan, 20%);
		text-decoration: none;

		&.btn {
			color: #fff;
		}

		&.carousel-control-next, &.carousel-control-prev {
			color: $tan;
			opacity: 1;
		}
	}

	& > .row {
		position: relative;

		@media only screen and (max-width: 767px) {
			margin-right: -20px;
			margin-left: -20px;
		}
	}

	.database-search {
		position: absolute;
		left: 50%;
		top: -77px;
		width: 100% !important;
		z-index: 50;
		display: flex;
		flex-flow: wrap;
		justify-content: center;
		padding: 1rem;
		border-top: 1px solid #fff;
		background-color: $gray-light;
		transition: transform 0.25s ease, opacity 0.25s ease;
		transform: translate(-50%, -100%);
        opacity: 0;

        &.lower {
            top: -30px;
        }

		@media only screen and (max-width: 991px) {
			position: relative;
			width: calc(100% + 10px);
			margin: 0 -5px;
			left: auto;
			top: -80px;
			padding: 0.5rem;
			transform: none;
			background-image: linear-gradient(to right, #c4c4c4, #9f9f9f 5%, #9f9f9f 50%, #9f9f9f 95%, #c4c4c4);

            &.database-search-open {
                transform: translate(0) !important;

                &.lower {
                    top: -10px;
                }
            }
		}

		@media only screen and (max-width: 767px) {
			background: #9f9f9f;
		}

		input {
			font-size: 1.1rem;
			line-height: 1em;

			@media only screen and (max-width: 991px) {
				font-size: 1rem;
				line-height: 1em;
				height: calc(1.6em + 0.75rem);
			}

			@media only screen and (max-width: 767px) {
				font-size: (14/15) * 1rem;
				line-height: 1em;
				height: calc(1.6em + 0.5rem)
			}
		}

		.input-group-text {
			font-size: 1rem;
			cursor: pointer;
		}

		&.database-search-open {
      opacity: 1;
			transform: translate(-50%, 0);
			box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
		}
	}

	.model-main-image {
		border: 5px solid #fff;
		box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
		display: block;
		width: 100%;
		height: auto;
		padding-top: 65%;
		max-height: 417px;
		margin: 0 0 25px;
        position: relative;
		overflow: hidden;

    .swatch-arrow, .swatch-arrow:visited {
      background-color: #000;
      width: 10%;
      height: 20%;
      position: absolute;
      left: 0;
      top: 50%;
      font-size: 45px;
      color: #fff;
      opacity: 0.25;
      transform: translateY(-50%);
      transition: opacity 0.3s ease;
      z-index: 50;

      .svg-inline--fa {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }

      @media only screen and (max-width: 767px) {
        top: 33%;
      }
    }

    .swatch-arrow-right, .swatch-arrow-right:visited {
      left: auto;
      right: 0;
    }

    .swatch-arrow:active, .swatch-arrow:hover, .swatch-arrow:focus {
      opacity: 0.75;
    }

        img {
    		display: block;
    		width: 100%;
    		height: auto;
            margin: 0 auto;
			position: absolute;
			left: 50%;
			top: 50%;
			transform: translate(-50%, -50%);
        }

        .model-main-details {
            position: absolute;
            bottom: 0;
            left: 0;
            padding: 1rem;
            background-color: rgba(0, 0, 0, 0.75);
            color: #fff;
            width: 100%;
            transition: transform 0.25s ease;
            border-top: 1px solid rgba(255, 255, 255, 0.5);

			&.model-main-details-hidden {
            	transform: translate(0, 100%);
			}

            .detail-title {
                display: block;
                font-size: 1.2rem;
                line-height: 1em;
                padding-bottom: 10px;
            }

            .detail-action {
                font-size: 1rem;
                line-height: 1em;
                margin-left: 1em;
                cursor: pointer;

                .svg-inline--fa {
                    margin-right: 5px;
                    font-size: 1em;
                }

                &.detail-action-alt {
                    color: $tan;
                }
            }

            .detail-icon {
                font-size: 0.9rem;
                line-height: 1em;
                margin-right: 1em;

                .svg-inline--fa {
                    margin-right: 3px;
                    font-size: 0.9em;
                }

                &.detail-icon-alt {
                    color: $tan;
                }

                .icon-hide {
                  display: none;
                }

                .icon-show {
                  display: inline-block !important;
                }
            }
        }
	}

	.swatch-resource-container {
		margin-top: 20px;

		h3 + & {
			margin-top: 0;
		}
	}
}

.form-samples-order {
	@extend .mt-4;
	@extend .pt-4;
	border-top: 1px solid $gray-light;

	label {
		font-size: 1rem;
		margin-bottom: 0.25rem;
	}

	legend {
		font-size: 1.35rem;
	}
}

.card-container {
	display: flex;
	flex-flow: wrap;
    justify-content: center;

	.card {
		flex-basis: 32%;
		margin-bottom: 1.5%;
		overflow: hidden;
		border: 3px solid #fff;
		box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);

    &.browse-category-square,
    &.browse-collection-square,
    &.browse-color-square,
    &.browse-productLine-square,
    &.browse-sample-square,
    &.browse-result-square,
    &.productLines-result-square {
			max-height: 173px;

			a {
				display: block;
				position: relative;
				padding-top: 65%;

				.card-img {
					height: auto;
					position: absolute;
					left: 50%;
					top: 50%;
					transform: translate(-50%, -50%);
				}
			}
    }

    &.swatch-result-square  {
			//max-height: 173px;

			a {
				display: block;
				position: relative;
				padding-top: 100%;

				.card-img {
					height: auto;
					position: absolute;
					left: 50%;
					top: 50%;
					transform: translate(-50%, -50%);
				}
			}
    }

    &.swatch-lightbox-square  {
			max-height: 105px;

			a {
				display: block;
				position: relative;
				padding-top: 80%;

				.card-img {
					height: auto;
					position: absolute;
					left: 50%;
					top: 50%;
					transform: translate(-50%, -50%);
          width: 125%;
				}
			}
    }

    &.gallery-result-square {
      max-height: 166px;

      a {
				display: block;
				position: relative;
        padding-top: 64%;

				.card-img {
					height: auto;
					position: absolute;
					left: 50%;
					top: 50%;
					transform: translate(-50%, -50%);
				}
      }
    }

		.content-pdf & {
			border: none;
			box-shadow: none;
			overflow: visible;
			margin-bottom: 2.5%;

			img {
				border: 1px solid $gray;
			}
		}

		& + .card {
			margin-left: 2%;
		}

		&:nth-of-type(3n+1) {
			margin-left: 0;
		}

		@media only screen and (max-width: 650px) {
			flex-basis: 49%;

			&:nth-of-type(3n+1) {
				margin-left: 2%;
			}

			&:nth-of-type(2n+1) {
				margin-left: 0;
			}
		}

		@media only screen and (max-width: 400px) {
			flex-basis: 100%;

			& + .card {
				margin-left: 0;
			}

			&:nth-of-type(3n+1) {
				margin-left: 0;
			}

			&:nth-of-type(2n+1) {
				margin-left: 0;
			}
		}

		.swap-swatch {
			cursor: default;
		}

		.card-img-overlay {
			top: auto;
			right: auto;
			padding: 1rem;
			background-color: rgba(0, 0, 0, 0.75);
			color: #fff;
			width: 100%;
			transition: transform 0.25s ease;
			transform: translate(0, 100%);
			border-top: 1px solid rgba(255, 255, 255, 0.5);

			h5 {
				margin: 0;
				font-family: $roboto-condensed;
				font-size: 1.1rem;
				line-height: 1em;
			}

			p {
				font-size: 0.9rem;
				margin-top: 0.5em;
				line-height: 1.2em;
			}

			&.card-img-overlay_review {
				transform: translate(0, 0);
				padding: 0.25rem 0.5rem;
				font-size: 0.75rem;
			}

			&.card-img-overlay_pdf {
				transform: translate(0, 0);
				position: static;
				background: none;
				color: $gray;
				padding: 0.75rem 0.5rem 0.5rem;

				span {
					display: block;
				}
			}

			&.card-img-overlay_full {
				padding: 0.5rem 0.75rem;
				height: 100%;
				background-color: rgba(0, 0, 0, 0.75);
				border: none;

				.overlay-title {
					text-align: center;
					text-transform: uppercase;
					font-size: 0.9rem;
					line-height: 1em;
					display: block;
					padding: 0.5em 0;
					clear: both;
          cursor: pointer;

          .text-tan {
            font-size: 0.9em;
            text-transform: none;
            display: block;
          }
				}

				.overlay-action, .overlay-icon {
					font-size: 0.8rem;
					line-height: 1em;
					display: block;
					padding: 0.75em 0 0;
					clear: both;

					&.overlay-action-alt, &.overlay-icon-alt {
						color: lighten($tan, 20%);
					}

					.svg-inline--fa {
						font-size: 1.1em;
						margin-right: 5px;
						margin-top: -1px;
						float: left;
						display: block;
					}
				}

				.overlay-action {
					cursor: pointer;

					&:hover {
						color: darken(#fff, 20%);

						&.overlay-action-alt {
							color: $tan;
						}
					}
				}

				.overlay-icon {
					.svg-inline--fa {
						font-size: 0.9em;
					}
				}

				hr {
					margin: 0.75em auto 0.25em;
					width: 50%;
					height: 1px;
					border: none;
					background-color: $gray-light;
				}
			}
		}

    &.browse-productLine-square {
      .card-img-overlay {
        transform: translate(0, 0);
      }
    }

        @media only screen and (max-width: 700px) {
            .card-img-overlay {
                transform: translate(0, -62px);

                &.card-img-overlay_full {
                    transform: translate(0, 113px);
                }
            }
        }




		&:hover {
			.card-img-overlay {
				transform: translate(0, 0);
			}
		}

		&.active-swatch {
			border-color: $townsend-blue;
			position: relative;
			opacity: 0.5;
			transition: opacity 0.25s ease;

			&:hover {
				.card-img-overlay {
					transform: translate(0, 100%);
				}
			}
		}
	}

  &.card-container-2 {
    .card {
      flex-basis: 49%;

      .content-pdf & {
        width: 49%;
        display: block;
        float: left;
      }

      & + .card {
        margin-left: 2%;
      }

      &:nth-of-type(4n+1), &:nth-of-type(3n+1) {
        margin-left: 2%;
      }

      &:nth-of-type(2n+1) {
        margin-left: 0;
      }
    }

    @media only screen and (max-width: 400px) {
      flex-basis: 100%;

      .content-pdf & {
        width: 100%;
        display: block;
        float: none;
      }

      & + .card {
        margin-left: 0;
      }

      &:nth-of-type(4n+1), &:nth-of-type(3n+1), &:nth-of-type(2n+1) {
        margin-left: 0;
      }
    }
  }

	&.card-container-4 {
		margin-top: 1.25rem;

		.card {
			flex-basis: 24%;

			.content-pdf & {
				width: 24%;
				display: block;
				float: left;
			}

			& + .card {
				margin-left: 1.333333333333333%;
			}

			&:nth-of-type(3n+1) {
				margin-left: 1.333333333333333%;
			}

			&:nth-of-type(4n+1) {
				margin-left: 0;
			}


            @media only screen and (max-width: 700px) {
                .card-img-overlay {
                    transform: translate(0, 0);
                    top: 46%;
                }
            }


			@media only screen and (max-width: 767px) {
				flex-basis: 32%;

				.content-pdf & {
					width: 32%;
					display: block;
					float: left;
				}

				& + .card {
					margin-left: 2%;
				}

				&:nth-of-type(4n+1) {
					margin-left: 2%;
				}

				&:nth-of-type(3n+1) {
					margin-left: 0;
				}
			}

			@media only screen and (max-width: 650px) {
				flex-basis: 49%;

				.content-pdf & {
					width: 49%;
					display: block;
					float: left;
				}

				& + .card {
					margin-left: 2%;
				}

				&:nth-of-type(4n+1), &:nth-of-type(3n+1) {
					margin-left: 2%;
				}

				&:nth-of-type(2n+1) {
					margin-left: 0;
				}
			}

			@media only screen and (max-width: 400px) {
				flex-basis: 100%;

				.content-pdf & {
					width: 100%;
					display: block;
					float: none;
				}

				& + .card {
					margin-left: 0;
				}

				&:nth-of-type(4n+1), &:nth-of-type(3n+1), &:nth-of-type(2n+1) {
					margin-left: 0;
				}
			}
		}
	}

	&.card-container-6 {
		.card {
			flex-basis: 16%;
			margin-bottom: 0.8%;

			& + .card {
				margin-left: 0.8%;
			}

			&:nth-of-type(3n+1) {
				margin-left: 0.8%;
			}

			&:nth-of-type(6n+1) {
				margin-left: 0;
			}

			.svg-inline--fa {
				float: left;
				display: block;
				margin-bottom: 3px;
			}

      .overlay-title {
        .svg-inline--fa {
          float: none;
          display: inline-block;
          margin: 3px 5px 0 0;
        }
      }

			@media only screen and (max-width: 991px) {
				flex-basis: 19.2%;
				margin-bottom: 1%;

				& + .card {
					margin-left: 1%;
				}

				&:nth-of-type(3n+1), &:nth-of-type(6n+1) {
					margin-left: 1%;
				}

				&:nth-of-type(5n+1) {
					margin-left: 0;
				}
			}

			@media only screen and (max-width: 767px) {
				flex-basis: 24.25%;

				& + .card {
					margin-left: 1%;
				}

				&:nth-of-type(3n+1), &:nth-of-type(6n+1), &:nth-of-type(5n+1) {
					margin-left: 1%;
				}

				&:nth-of-type(4n+1) {
					margin-left: 0;
				}
			}

			@media only screen and (max-width: 600px) {
				flex-basis: 32.666666666666667%;

				& + .card {
					margin-left: 1%;
				}

				&:nth-of-type(4n+1), &:nth-of-type(6n+1), &:nth-of-type(5n+1) {
					margin-left: 1%;
				}

				&:nth-of-type(3n+1) {
					margin-left: 0;
				}
			}

			@media only screen and (max-width: 450px) {
				flex-basis: 49.5%;

				& + .card {
					margin-left: 1%;
				}

				&:nth-of-type(3n+1), &:nth-of-type(4n+1), &:nth-of-type(6n+1), &:nth-of-type(5n+1) {
					margin-left: 1%;
				}

				&:nth-of-type(2n+1) {
					margin-left: 0;
				}
			}

			@media only screen and (max-width: 350px) {
				flex-basis: 100%;
				margin-bottom: 1.5%;

				& + .card, &:nth-of-type(3n+1), &:nth-of-type(4n+1), &:nth-of-type(6n+1), &:nth-of-type(5n+1), &:nth-of-type(2n+1) {
					margin-left: 0;
				}
			}
		}
	}

	&.card-container-10 {
		.card {
			flex-basis: 10%;
			margin-bottom: 0;
			box-shadow: none;
			padding: 2px;
			background-color: rgba($gray, 0.5);
			border-width: 2px;

			& + .card, &:nth-of-type(3n+1), &:nth-of-type(6n+1) {
				margin-left: 0;
			}
		}
	}
}

.carousel-item {
	overflow: hidden;
	background-color: #fff;
	padding-top: 80vh;

	img {
		height: auto;
		max-height: 100%;
		width: auto;
		max-width: 100%;
		display: block;
		margin: 0 auto;
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
	}

	.carousel-caption {
		background-color: rgba(0, 0, 0, 0.5);
		bottom: 0;
		left: 0;
		right: 0;
		padding-bottom: 30px;

		p {
			font-size: 1rem;
			margin-top: 0.5em;
		}
	}
}

.modal {
	left: 50%;
	transform: translate(-50%, 0);

	.modal-dialog {
		max-width: 1100px;
	}

	.close {
		font-size: 1.8rem;
	}

	.carousel-indicators {
		margin-bottom: 0.25rem;
	}
}

footer.container, footer.container-fluid {
	//max-width: 1140px;
	padding-bottom: 35px;
	padding-top: 35px;
	box-shadow: inset 0 7px 6px -7px rgba(0, 0, 0, 0.5);
	//border-radius: 5px;

	& > .container {
		max-width: 1140px;
	}

	@media only screen and (max-width: 991px) {
		padding: 20px;

		.col-md-3 {
			flex: 0 0 30%;
    		max-width: 30%;
		}

		.col-md-4 {
			flex: 0 0 40%;
    		max-width: 40%;
		}
	}

	@media only screen and (max-width: 767px) {
		.col-md-3, .col-md-4 {
			flex: 0 0 100%;
    		max-width: 100%;
			text-align: center;
		}

		.col-md-3 {
			margin-top: 1rem;
		}
	}

	.content-pdf & {
		max-width: none;
		padding: 35px;
		box-shadow: none;
		border-radius: 0;
		border-top: 1px solid $gray-light;
	}

	a, a:visited, a:active, a:focus {
		color: $townsend-blue;
		text-decoration: none;
	}

	a:hover {
		color: darken($townsend-blue, 20%);
		text-decoration: none;
	}

	address, p {
		font-size: 1rem;
	}

	.svg-inline--fa {
		font-size: 2.5rem;
		line-height: 1rem;
		margin-left: 5px;
	}
}

.lightbox-container {
	position: fixed;
	right: 0;
	top: 0;
	width: 80vw;
	height: 100vh;
	z-index: 99999;
	background-color: #fcfcfc;
	border-left: 1px solid $gray;
	max-width: 900px;
	transform: translate(100%, 0);
	transition: transform 0.5s ease;

	@media only screen and (max-width: 991px) {
		width: 100vw;
	}

	.lightbox-utilities {
		margin: -0.5rem;
		padding: 0.5rem;
		background-color: lighten($gray-light, 30%);

		.btn {
			@media only screen and (max-width: 991px) {
				font-size: 0.8rem;
				padding: 0.25rem 0.5rem;
			}

			@media only screen and (max-width: 767px) {
				flex-basis: 19.2%;
				margin-right: 0 !important;
				line-height: 1.25em;

				& + .btn {
					margin-right: 1% !important;
				}
			}

			@media only screen and (max-width: 450px) {
				font-size: 0.75rem;
				padding: 0.25rem;
			}
		}

		@media only screen and (max-width: 767px) {
			display: flex;
			flex-flow: row;
			justify-content: space-between;

			.lightbox-delete {
				order: 1;
			}

			.clear {
				display: none;
			}
		}
	}

	&.lightbox-active {
		transform: translate(0, 0);

        .lightbox-indicator {
            left: -48px;

        }
	}

	.lightbox-order, .lightbox-change, .lightbox-share, .lightbox-pdf {
		margin-right: 5px;
	}

	.lightbox-indicator {
		position: absolute;
		top: 60px;
		border-width: 1px;
		border-color: $gray rgba(0, 0, 0, 0.125) $gray $gray;
		border-style: solid;
		left: -175px;
		padding: 3px 10px 0 1px;
		background-color: #f7f7f7;
		cursor: pointer;
        display: flex;
        align-items: center;
        transition: all ease-in-out 1s;

		.svg-inline--fa {
			color: darken($townsend-blue, 10%);
		}

		.fa-layer-group {
			color: $townsend-blue;
		}

        span {
            display: block;
        }


        @media only screen and (max-width: 767px) {
            font-size: 13px;
            left: -153px;
        }
	}

	.lightbox-content {
		border: none;
		border-radius: 0;

		.card-header {
			border-radius: 0;

			h3 {
				color: darken($townsend-blue, 10%);

				.lightbox-close {
					float: right;
					display: block;
					font-size: 0.6em;
					text-transform: uppercase;

					.svg-inline--fa {
						font-size: 1.1em;
						margin-left: 5px;
					}

					@media only screen and (max-width: 991px) {
						padding: 0.25rem 0.5rem;
					}

					@media only screen and (max-width: 767px) {
						float: none;
						margin-top: 10px;
						max-width: 90px;
					}
				}
			}
		}

		.card-body {
			background-color: transparent;

			@media only screen and (max-width: 450px) {
				padding: 0.75rem;
			}

			.form-inline .form-control {
				display: inline-block;
			    width: auto;
			    vertical-align: middle;
			}

			.lightbox-sub-head {
				.sub-head_text {
					cursor: pointer;
				}

				.title-update-field {
					padding: 2px;
					background-color: rgba($gray-light, 0.5);
					border-radius: 5px;

					button {
						margin-left: 5px;
					}

					.form-control {
						display: flex;
					}
				}
			}

			.lightbox-card {
				@extend .card-container;
				@extend .mt-3;
				padding: 5px;
			}
		}
	}
}

.lightbox-form-share {
	.card-body {
		position: relative;
		overflow: visible !important;
	}
}

.lightbox-user-search-text {
	position: relative;

	button {
		margin-left: 5px;
	}

	.search-loading {
		padding: 0 10px;
		position: absolute;
		right: 75px;
		top: 50%;
		transform: translateY(-50%);
		width: 25%;
	}
}

.lightbox-user-search-results {
	max-height: 150px;
	overflow: auto;
	position: absolute;
	left: 0;
	top: 99%;
	z-index: 999;
	width: calc(100% + 2px);
	margin-left: -1px;
	margin-right: -1px;
	border-left: 1px solid rgba(0, 0, 0, 0.125);
	border-right: 1px solid rgba(0, 0, 0, 0.125);
	border-radius: 0 0 5px 5px;

	.list-group {
		border-top: 1px solid rgba(0, 0, 0, 0.125);
		border-bottom: 1px solid rgba(0, 0, 0, 0.125);
		background-color: #fff;
	}

	.list-group-item {
		padding: 0.5rem 1.25rem;
		cursor: pointer;

		&:hover, &.active-select {
			background-color: rgba($townsend-blue, 0.25);
		}
	}
}

.toast-container {
	position: fixed;
	top: 90px;
	right: 0;
	min-height: 200px;
	z-index: 9999999;

	.toast-content {
		position: absolute;
		top: 0;
		right: 25px;

		.toast-header {
			font-size: 1rem;
			padding: 0.5rem 0.75rem;
			min-width: 300px;
			color: #fff;

			img {
				width: 25px;
				height: 25px;
				display: inline-block;
				margin: 0 5px 0 0;
			}

			button {
				font-size: 1em;
			}

			.text-muted, button {
				color: #fff !important;
			}
		}

		.toast-body {
			background-color: #f7f7f7;

			p {
				font-size: 1rem;
			}
		}
	}
}

.sidebar-box {
  padding: 0 20px;
  //background-color: #f7f7f7;

    h2 {
        text-align: left;
    }
}

.mobile {
    display: none;
}

@media only screen and (max-width: 991px) {
    .mobile {
        display: block;
    }
}


.sidebar-box {
    ul.sidebar {
        margin: 0;
        padding: 0;

        li {
            list-style-type: none;
            display: block;
            margin-top: 15px;
            padding-bottom: 15px;
            border-bottom: 1px solid #cccccc;

            &:last-child {
                border-bottom: none;
            }


            &:first-child {
                margin-top: 0;
            }

            a {
                span {
                    display: block;

                    &.title {
                        font-size: 18px;
                        font-weight: 600;
                        margin-bottom: 5px;
                    }

                    &.text {
                        font-size: 14px;
                    }
                }
            }
        }
    }
}


.center-text {
    text-align: center;
}


.inset {
    background-color: #f9f4f4;
}




body.modal-open {
    .lightbox-container {
        display: none;
    }
}


