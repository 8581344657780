// Body
$body-bg: #f8fafc;

// Typography
$pragmatica: "Roboto Condensed", sans-serif;
$arial: "Open Sans", sans-serif;
$roboto-condensed: 'Roboto Condensed', 'Arial Narrow', Arial, sans-serif;
$font-size-base: 0.9rem;
$line-height-base: 1.6;

// Colors
$blue: #3490dc;
$indigo: #6574cd;
$purple: #9561e2;
$pink: #f66d9b;
$red: #e3342f;
$orange: #f6993f;
$yellow: #ffed4a;
$green: #38c172;
$teal: #4dc0b5;
$cyan: #6cb2eb;

$tan-light: #bca786;
$tan: #a99678;
$gray: #5b5b5b;
$gray-light: #9f9f9f;
$gray-medium: #838383;
$townsend-green: #9da452;
$townsend-blue: #75909e;
$townsend-orange: #d79473;
$townsend-gray: #808084;

%screen-reader-text {
	position: absolute;
	top:      -9999em;
	left:     -9999em;
}
